import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {Text, LoginScreen} from '@storyvoice/shared/lib/components';
import {auth} from '@storyvoice/shared/lib/firebase';
import {getStreamCodeById} from '@storyvoice/shared/lib/models';

export default function Login(): React.ReactElement {
  const history = useHistory();
  const [message, setMessage] = useState('');

  const goToStream = useCallback(async () => {
    const uid = auth().currentUser?.uid;
    if (uid) {
      const streamCode = await getStreamCodeById(uid);
      streamCode && history.push(`/streams/${streamCode}`);
    }
  }, [history]);

  useEffect(() => {
    if (auth().currentUser && auth().currentUser?.email) {
      setMessage(
        `You are already logged in as ${
          auth().currentUser?.email
        }. Redirecting...`,
      );
      setTimeout(goToStream, 3000);
    }
  }, [goToStream]);

  return message ? (
    <Text category="h5">{message}</Text>
  ) : (
    <LoginScreen
      onSubmit={async (email, password) => {
        await auth().signInWithEmailAndPassword(email, password);
        goToStream();
      }}
    />
  );
}
