import createPersistedState from 'use-persisted-state';

const useStreamCode = createPersistedState('streamCode');

type UseUserStreamCodeResult = {
  streamCode: string;
  joinStream: (code: string) => void;
  leaveStream: () => void;
};

export function useUserStreamCode(): UseUserStreamCodeResult {
  const [streamCode, setStreamCode] = useStreamCode<string>('public');

  return {
    streamCode,
    joinStream: (code: string) => code !== 'public' && setStreamCode(code),
    leaveStream: () => setStreamCode('public'),
  };
}
