import React, {ReactElement} from 'react';
import {Linking, StyleSheet} from 'react-native';
import {Bar, useTheme, Text} from '@storyvoice/shared/lib/components';

import {barStyles} from './common';

export function TeacherBar(): ReactElement {
  const theme = useTheme();

  const handlePress = () =>
    Linking.openURL('https://app.storyvoice.live/check');

  return (
    <Bar color={theme['color-info-500']} icon="info">
      <Text status="control" category="p1">
        <Text status="control" style={styles.textBold} category="p1">
          Teachers
        </Text>
        - If using school-issued device or Wi-Fi, please run our quick
        technology check for a smooth read-aloud experience.{' '}
        <Text
          status="control"
          category="p1"
          onPress={handlePress}
          style={barStyles.link}>
          Run Check.
        </Text>
      </Text>
    </Bar>
  );
}

const styles = StyleSheet.create({
  textBold: {fontWeight: 'bold'},
});
