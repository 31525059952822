import React from 'react';
import {StyleSheet, View, Image} from 'react-native';
import {useHistory} from 'react-router-dom';

import {Button, Header, Text} from '@storyvoice/shared/lib/components';
import {Images} from '@storyvoice/shared/lib/constants';

export function NotFoundScreen(): React.ReactElement {
  const history = useHistory();

  const handleBack = () => history.push('/');

  return (
    <View>
      <Header withLogo />
      <View style={styles.container}>
        <Image source={Images.girlWaitingByWindow} style={styles.image} />
        <Text category="h4" style={styles.text}>
          Not Found - This is not the webpage you are looking for.
        </Text>

        <Button style={styles.button} onPress={handleBack}>
          Go back to Home
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {marginTop: 20},
  container: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: 30,
  },
  image: {height: 220, margin: 20, width: 300},
  text: {textAlign: 'center'},
});
