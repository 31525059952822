import React, {useEffect, useState} from 'react';
import {I18nManager, Image, StyleSheet, Text, View} from 'react-native';
import {useHistory, useParams} from 'react-router-dom';
import {subscribeToSessionStatus} from '@storyvoice/shared/lib/models';
import {AudienceSession, SessionCTA} from '@storyvoice/shared/lib/containers';
import {SessionStatus} from '@storyvoice/shared/lib/types';
import {logEvent, Events} from '@storyvoice/shared/lib/utils';

import Logo from '../../../../assets/logo.png';

export default function Session(): React.ReactElement {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [error, setError] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  // disable RTL in session
  useEffect(() => {
    if (I18nManager.isRTL) {
      I18nManager.forceRTL(false);
      return () => {
        I18nManager.forceRTL(true);
      };
    }

    return;
  }, []);

  useEffect(() => {
    if (!error) {
      return subscribeToSessionStatus(id, (session) => {
        session?.status === SessionStatus.Live &&
          logEvent(Events.JOIN_READING_SESSION, {
            streamCode: session.streamCode,
            sessionId: session.id,
          });

        if (!session?.id) {
          setError(true);
        } else if (session?.status === SessionStatus.Completed) {
          setShowFeedback(true);
        } else if (session?.status === SessionStatus.Scheduled) {
          history.goBack();
        }
      });
    }
    return;
  }, [error, id, history]);

  const onFeedbackAction = () => {
    history.push('/');
  };

  return (
    <>
      {error ? (
        <>
          <View style={styles.container}>
            <Image
              style={styles.logo}
              source={{uri: Logo, height: 100, width: 300}}
            />

            <Text style={styles.text}>Session is not valid</Text>
          </View>
        </>
      ) : (
        <View style={styles.session}>
          <AudienceSession sessionId={id} />
          <SessionCTA
            sessionId={id}
            visible={showFeedback}
            onSubmit={onFeedbackAction}
          />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 'auto',
    maxWidth: 500,
    textAlign: 'center',
    width: 300,
  },
  logo: {
    marginBottom: 48,
  },
  session: {
    maxHeight: '90vh',
    maxWidth: '100vw',
  },
  text: {
    fontSize: 16,
  },
});
