import React from 'react';
import {useHistory} from 'react-router-dom';
import {TechnologyCheck} from '@storyvoice/shared/lib/components';
import {createReport} from '@storyvoice/shared/lib/models/reports';

export default function Check({full}: {full?: boolean}): React.ReactElement {
  const history = useHistory();
  return (
    <TechnologyCheck
      useAllChecks={full}
      onComplete={async (results) => {
        const report = await createReport(results);
        history.push(`/reports/${report.id}`);
      }}
    />
  );
}
