import React from 'react';
import {BrowserRouter, Switch, Redirect, Route} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import {MobileGate, Session, Stream, Login, SessionDetails} from './pages';
import Check from './pages/check';
import Report from './pages/reports/[reportId]/index';
import {useUserStreamCode} from './hooks';

export default function Router(): React.ReactElement {
  const {streamCode} = useUserStreamCode();

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/test">
          <Redirect to="/check" />
        </Route>
        <Route exact path="/check">
          <Check />
        </Route>
        <Route exact path="/check/full">
          <Check full={true} />
        </Route>
        <Route exact path="/reports/:id">
          <Report />
        </Route>
        <Route exact path="/reports/:id/it">
          <Report withITHeader />
        </Route>
        <Route exact path="/mobile-gate">
          <MobileGate />
        </Route>

        {isMobile && (
          <Route path="*">
            <Redirect to="/mobile-gate" />
          </Route>
        )}

        <Route exact path="/">
          <Redirect to={`/streams/${streamCode}`} />
        </Route>

        <Route exact path="/sessions/:id">
          <Session />
        </Route>

        <Route exact path="/sessions/:id/info">
          <SessionDetails />
        </Route>

        <Route exact path="/streams/:code">
          <Stream />
        </Route>

        <Route exact path="/login">
          <Login />
        </Route>

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
