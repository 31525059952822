import React from 'react';
import {
  View,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  Linking,
} from 'react-native';

import Logo from '../../assets/logo.png';
import AppStoreDownload from '../../assets/app-store.png';
import PlayStoreDownload from '../../assets/play-store.png';

export default function Home(): React.ReactElement {
  return (
    <View style={styles.container}>
      <Image style={styles.logo} source={{uri: Logo, height: 100}} />

      <Text style={styles.headerText}>Welcome to Storyvoice!</Text>
      <Text style={styles.bodyText}>
        Please download the Storyvoice mobile app to access our live reading
        sessions over your phone or tablet device.
      </Text>
      <TouchableOpacity
        onPress={() =>
          Linking.openURL(
            'https://apps.apple.com/gb/app/storyvoice-live-storytelling/id1496861562',
          )
        }>
        <Image
          resizeMode="contain"
          style={styles.download}
          source={{uri: AppStoreDownload}}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() =>
          Linking.openURL(
            'https://play.google.com/store/apps/details?id=io.kindi.storyvoice',
          )
        }>
        <Image
          resizeMode="contain"
          style={styles.download}
          source={{uri: PlayStoreDownload}}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  bodyText: {
    fontSize: 16,
    marginTop: 20,
    textAlign: 'center',
  },
  container: {
    margin: 'auto',
    maxWidth: 500,
    width: 300,
  },
  download: {
    alignSelf: 'center',
    height: 100,
    width: 200,
  },
  headerText: {
    fontSize: 20,
    fontWeight: '700',
    textAlign: 'center',
  },
  logo: {
    marginBottom: 36,
  },
});
