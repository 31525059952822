import React, {ReactElement} from 'react';
import {Image, Linking, StyleSheet} from 'react-native';

import Logo from './chaport-logo.png';

const BUTTON_MARGIN = '1.42857em';
const BUTTON_SIZE = '4.28571em';
const CHAPORT_LINK =
  process.env.CHAPORT_LINK ??
  'https://app.chaport.com/widget/show.html?appid=601a4863973f417a11f7c39f';

export function ChaportButton(): ReactElement {
  return (
    <Image
      source={{uri: Logo}}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore styles aren't truly react-native, although they still pass through
      style={styles.container}
      onClick={() => Linking.openURL(CHAPORT_LINK)}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    bottom: BUTTON_MARGIN,
    cursor: 'pointer',
    height: BUTTON_SIZE,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore fixed position isn't truly react-native, although they still pass through
    position: 'fixed',
    right: BUTTON_MARGIN,
    width: BUTTON_SIZE,
  },
});
