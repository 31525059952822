import React, {ReactElement} from 'react';
import {Linking} from 'react-native';
import {Bar, useTheme, Text} from '@storyvoice/shared/lib/components';
import {barStyles} from './common';

export function CheckFailedBar(): ReactElement {
  const theme = useTheme();

  const handlePress = () =>
    Linking.openURL('https://app.storyvoice.live/check');

  return (
    <Bar color={theme['color-danger-500']} icon="alert-triangle">
      <Text status="control" category="h6">
        Something is blocking Storyvoice on your device.{' '}
        <Text
          status="control"
          category="h6"
          onPress={handlePress}
          style={barStyles.link}>
          Please run our technology check{' '}
        </Text>
        to determine the issue.
      </Text>
    </Bar>
  );
}
