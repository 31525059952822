import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import dayjs from 'dayjs';

import {logEvent, Events} from '@storyvoice/shared/lib/utils';
import {subscribeToSessionStatus} from '@storyvoice/shared/lib/models';
import {SessionStatus, SVSession} from '@storyvoice/shared/lib/types';
import {
  LoadingScreen,
  SessionInfo,
  Text,
} from '@storyvoice/shared/lib/components';
import {getVariant} from '@storyvoice/shared/lib/components/Feed/helpers';

export default function SessionDetails(): React.ReactElement {
  const {id} = useParams<{id: string}>();
  const history = useHistory();
  const [session, setSession] = useState<SVSession>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return subscribeToSessionStatus(id, (session) => {
      setLoading(false);
      if (!session) {
        setError(true);
      } else if (session.status === SessionStatus.Live) {
        history.replace(`/sessions/${session.id}`);
        logEvent(Events.JOIN_SESSION_THROUGH_INFO);
      } else {
        setSession(session);
      }
    });
  }, [id, history]);

  const dateTime = dayjs(session?.scheduledAt);

  if (loading) {
    return <LoadingScreen />;
  }

  return !error ? (
    <SessionInfo
      goBack={() => history.goBack()}
      variant={getVariant(dateTime, session?.status === SessionStatus.Live)}
      dateTime={dateTime}
      sessionInfo={session?.description}
      coverImageSource={{
        uri: session?.coverUrl,
      }}
    />
  ) : (
    <Text category="h2" style={{alignSelf: 'center', padding: 100}}>
      Session Not Found
    </Text>
  );
}
