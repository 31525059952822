import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getReportById} from '@storyvoice/shared/lib/models/reports';
import {StoryvoiceReport} from '@storyvoice/shared/lib/types';
import {ReportPage} from '@storyvoice/shared/lib/components/screens/TechnologyCheck/Report/ReportPage';
import {LoadingScreen} from '@storyvoice/shared/lib/components';

import {NotFoundScreen} from '../../../components';

type ReportProps = {
  withITHeader?: boolean;
};

export default function Report({
  withITHeader,
}: ReportProps): React.ReactElement {
  const {id} = useParams<{id: string}>();
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState<StoryvoiceReport>();

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setReport(await getReportById(id));
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [id]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!report) {
    return <NotFoundScreen />;
  }

  return <ReportPage report={report} withITHeader={withITHeader} />;
}
