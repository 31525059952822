import React, {ReactElement} from 'react';
import {StyleSheet} from 'react-native';
import {Bar, useTheme, Text} from '@storyvoice/shared/lib/components';
import firebase from '@storyvoice/shared/lib/firebase';
import {Colors} from '@storyvoice/shared/lib/constants';

export type CopilotBarProps = {
  user: firebase.User | null;
  onSignout: () => void;
};

export function CopilotBar({
  user,
  onSignout,
}: CopilotBarProps): ReactElement | null {
  const theme = useTheme();

  if (!user?.email) {
    return null;
  }

  return (
    <Bar color={theme['color-warning-500']}>
      <Text category="h6" style={styles.text}>
        You are logged in as a co-pilot 👩‍✈️
      </Text>

      <Text category="h6" style={styles.signOutText}>
        {user?.email}{' '}
        <Text category="h6" status="primary" onPress={onSignout}>
          (Sign out)
        </Text>
      </Text>
    </Bar>
  );
}

const styles = StyleSheet.create({
  signOutText: {
    color: Colors.white,
    position: 'absolute',
    right: 50,
    textAlign: 'center',
  },
  text: {color: Colors.white, textAlign: 'center'},
});
