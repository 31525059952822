import React, {useEffect, useState} from 'react';
import {Linking} from 'react-native';
import {useHistory, useParams} from 'react-router-dom';
import {
  checkStreamCode,
  getStreamByCode,
  subscribeToStreamSessionsFeed,
} from '@storyvoice/shared/lib/models';
import {
  AudienceHome,
  LoadingScreen,
  TechnologyCheckModal,
} from '@storyvoice/shared/lib/components';
import {buildAsyncHook} from '@storyvoice/shared/lib/utils';
import {SVSession, SVStream} from '@storyvoice/shared/lib/types';
import firebase, {auth} from '@storyvoice/shared/lib/firebase';
import {
  useFirebaseCheck,
  useDeviceCheck,
} from '@storyvoice/shared/lib/hooks/checks';
import {useTechnologyCheckReminder} from '@storyvoice/shared/lib/hooks';
import * as audienceModel from '@storyvoice/shared/lib/models/audiences';

import {useUserStreamCode} from '../../hooks';
import {CheckFailedBar, NotFoundScreen, TeacherBar} from '../../components';
import {ChaportButton} from '../../components/ChaportButton';
import {CopilotBar} from '../../components/bars';
import {ReminderModal} from '../../components/modals';

function TechnologyCheckBar() {
  const {result: firebaseResult} = useFirebaseCheck();
  const {result: deviceResult} = useDeviceCheck();

  const checkRunning = !firebaseResult || !deviceResult;

  const passed =
    checkRunning ||
    (firebaseResult?.auth.passed &&
      firebaseResult?.firestore.passed &&
      firebaseResult?.rtdb.passed &&
      deviceResult?.passed);

  if (passed) {
    return <TeacherBar />;
  }

  return <CheckFailedBar />;
}

export default function Stream(): React.ReactElement {
  const {code} = useParams<{code: string}>();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [sessions, setSessions] = useState<SVSession[]>([]);
  const [user, setUser] = useState<firebase.User | null>(null);
  const {joinStream, leaveStream} = useUserStreamCode();
  const [reminderVisible, setReminderVisibility] = useState(false);

  const {shouldShowReminder, hideReminder} = useTechnologyCheckReminder();

  useEffect(() => {
    auth().onAuthStateChanged((authUser) => {
      setUser(authUser);
    });
  }, []);

  useEffect(() => {
    (async () => {
      const streamExists = await checkStreamCode(code);
      if (streamExists) {
        setLoading(false);
        setError(false);
        joinStream(code);
      } else {
        setError(true);
        setLoading(false);
      }
    })();
  }, [code, joinStream]);

  useEffect(() => {
    if (code && user) {
      audienceModel.joinStream(user.uid, code);
    }
  }, [code, user]);

  useEffect(() => {
    if (!error) {
      return buildAsyncHook(async () => {
        const stream = (await getStreamByCode(code)).data() as SVStream;
        if (!stream) {
          return;
        }

        return subscribeToStreamSessionsFeed(stream, setSessions);
      });
    }
    return;
  }, [error, code]);

  if (loading) {
    return <LoadingScreen />;
  }
  if (error) {
    return <NotFoundScreen />;
  }

  const navigateToLiveSession = (session: SVSession) =>
    history.push(`/sessions/${session.id}`);

  const navigateToSessionInfo = (session: SVSession) =>
    history.push(`/sessions/${session.id}/info`);

  const handleSignout = async () => {
    try {
      setLoading(true);
      await auth().signOut();
      leaveStream();
      history.push('/');
    } finally {
      setLoading(false);
    }
  };

  const handleRunCheck = () => {
    Linking.openURL('https://app.storyvoice.live/check');
    hideReminder();
  };

  const handleSkipCheck = () => {
    hideReminder();
  };

  const handleSetSessionReminder =
    code === 'appleteachers'
      ? () => {
          setReminderVisibility(true);
        }
      : undefined;

  const handleDismissReminder = () => setReminderVisibility(false);
  return (
    <>
      <ReminderModal
        isVisible={reminderVisible}
        onDismiss={handleDismissReminder}
      />
      <TechnologyCheckBar />
      <CopilotBar onSignout={handleSignout} user={user} />
      <TechnologyCheckModal
        visible={shouldShowReminder}
        onRunCheck={handleRunCheck}
        onSkip={handleSkipCheck}
      />
      <AudienceHome
        streamCode={code}
        sessions={sessions}
        navigateToLiveSession={navigateToLiveSession}
        navigateToSessionInfo={navigateToSessionInfo}
        setSessionReminder={handleSetSessionReminder}
      />
      <ChaportButton />
    </>
  );
}
