import React from 'react';
import {ReactElement} from 'react';
import {Modal} from '@storyvoice/shared/lib/components';

type ReminderModalProps = {
  isVisible: boolean;
  onDismiss?: () => void;
};

export function ReminderModal({
  isVisible,
  onDismiss,
}: ReminderModalProps): ReactElement {
  return (
    <Modal visible={isVisible} onDismiss={onDismiss} testID="ReminderModal">
      <iframe
        width={600}
        height={430}
        style={{
          borderWidth: 0,
        }}
        src="https://cdn.forms-content.sg-form.com/881ff8e0-2755-11ec-956b-4a085793bdf9"
      />
    </Modal>
  );
}
