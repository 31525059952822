import React, {useEffect, useState} from 'react';
import {I18nManager} from 'react-native';
import {useFonts} from 'expo-font';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {ThemeProvider} from '@storyvoice/shared/lib/theme';
import {auth} from '@storyvoice/shared/lib/firebase';
import {LoadingScreen} from '@storyvoice/shared/lib/components';
import {useRemoteConfig} from '@storyvoice/shared/lib/hooks';
import {Config} from '@storyvoice/shared/lib/config';
import {
  LocalizationProvider,
  useLocalizationContext,
} from '@storyvoice/shared/lib/context';

import Router from './router';

if (Config.Sentry.DSN) {
  Sentry.init({
    dsn: Config.Sentry.DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: Config.Sentry.TRACES_SAMPLE_RATE,
  });
}

export default function AppWithLocalization(): React.ReactElement {
  return (
    <LocalizationProvider>
      <App />
    </LocalizationProvider>
  );
}

function App(): React.ReactElement {
  const [fontsLoaded] = useFonts({
    'Apercu-Light': require('@storyvoice/shared/assets/fonts/Apercu-Light.otf'),
    'Apercu-Bold': require('@storyvoice/shared/assets/fonts/Apercu-Bold.otf'),
    'Apercu-Regular': require('@storyvoice/shared/assets/fonts/Apercu-Regular.otf'),
    'Apercu-Medium': require('@storyvoice/shared/assets/fonts/Apercu-Medium.otf'),
  });

  const {initializeAppLanguage, appLanguage} = useLocalizationContext();
  const [authLoading, setAuthLoading] = useState(true);
  const {fetching} = useRemoteConfig();

  useEffect(() => {
    auth().onAuthStateChanged(async (user) => {
      // auth().signInAnonymously() triggers a `createAudienceAccount`
      // firebase function that sets the claims of the audience user.
      // However, these new claims were not always being reflected in
      // token that the firebase client has cached causing
      // PERMISSION_DENIED errors. To minimize the possibility of this
      // happening, we moved the signInAnonymously logic here and added
      // a forced token refresh call to make sure that the token that
      // firebase has internally includes the claims that we set.
      if (!user) {
        await auth().signInAnonymously();
        await auth().currentUser?.getIdToken(true);
      }
      setAuthLoading(false);
    });
  }, []);

  useEffect(() => {
    initializeAppLanguage();
  }, [initializeAppLanguage]);

  useEffect(() => {
    I18nManager.forceRTL(appLanguage === 'ar');
  }, [appLanguage]);

  return (
    <ThemeProvider>
      {fetching || authLoading || !fontsLoaded ? <LoadingScreen /> : <Router />}
    </ThemeProvider>
  );
}
